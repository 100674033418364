import React from "react";

import IconLink from "../components/iconLink.js";

import { faUntappd } from "@fortawesome/free-brands-svg-icons";

export const AboutMe = () => (
    <section>
        <h3>More about me</h3>
        <p>
            I have always been a tech enthusiast and I love building stuff (from LEGOs to IKEA furniture), so building software
            was a natural transition.
        </p>
        <p>
            I have a genuine interest in Fintech and how technology can improve one of the modern world&apos;s core industries.
        </p>
        <p>
            Besides tech/software, I like to learn about politics and economies around the world and how they help shape
            our society.
        </p>
        <p>
            Oh, and I&apos;m a craft beer lover. Check my long list of IPAs on my {" "}
            {<IconLink href="https://untappd.com/user/tiagofragoso" text="Untappd" icon={faUntappd} side="right" />}.
        </p>
    </section>
);

export default AboutMe;
