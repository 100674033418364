import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import remark from "remark";
import remarkHtml from "remark-html";

import { MIN_TABLET_MEDIA_QUERY } from "typography-breakpoint-constants";

const useStyles = createUseStyles({
    card: {
        marginBottom: "1em",
    },
    cardHeader: {
        display: "flex",
        justifyContent: "center",
        borderBottom: "1px solid var(--faded-border-color)",
        "& h3": {
            margin: "0.5em 0",
        },
    },
    cardContent: {
        padding: "2em .5em 0em .5em",
        "& p": {
            marginBottom: 0,
        },
    },
    itemList: {
        display: "flex",
        flexDirection: "column",
    },
    item: {
        display: "flex",
        marginBottom: "1em",
        "& > div > p": {
            display: "inline-block",
            marginLeft: "1.5em",
        },
    },
    content: {
        marginLeft: "0.2em",
        "& > p::before": {
            content: "\"> \"",
            fontWeight: "bold",
            marginRight: "1.4em",
        },
    },
    [MIN_TABLET_MEDIA_QUERY]: {
        card: {
            boxShadow: "0px 0px 4px 0px var(--box-shadow-color)",
            backgroundColor: "var(--card-bg)",
            marginBottom: "2em",
        },
        cardContent: {
            padding: "1em 2em",
        },
    },
});

const PageCard = ({ title, items, html }) => {
    const classes = useStyles();

    const textToHTML = (text) => (
        remark()
            .use(remarkHtml)
            .processSync(text)
            .toString()
    );

    const ListItem = ({ item: { icon, text } }) => (
        <div className={classes.item}>
            <span>{icon}</span>
            <div dangerouslySetInnerHTML={{ __html: textToHTML(text) }} />
        </div>
    );

    ListItem.propTypes = {
        item: PropTypes.shape({
            icon: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }),
    };

    return (
        <section className={classes.card}>
            <header className={classes.cardHeader}>
                <h3>{title}</h3>
            </header>
            <div className={classes.cardContent}>
                {items.length > 0 &&
                <div className={classes.itemList}>
                    {items.map((item, i) => (<ListItem key={i} item={item}/>))}
                </div>
                }
                <div className={classes.content} dangerouslySetInnerHTML={{ __html: html }} />
            </div>

        </section>
    );
};

PageCard.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired,
    html: PropTypes.string,
};


export default PageCard;
