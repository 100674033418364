import React from "react";

import useIntroCard from "../hooks/introCard";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageCard from "../components/pageCard";
import Contacts from "../components/contacts";
import AboutMe from "../components/aboutMe";

const IndexPage = () => {

    const { title, items, html } = useIntroCard();

    return (
        <Layout>
            <SEO title="Home" />
            <PageCard title={title || "whoami"} items={items} html={html} />
            <Contacts />
            <AboutMe />
        </Layout>
    );
};

export default IndexPage;
