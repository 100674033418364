import { useStaticQuery, graphql } from "gatsby";

export default () => {
    const { markdownRemark: { frontmatter: { title, items }, html } } = useStaticQuery(
        graphql`
        query IntroCard {
          markdownRemark(fileAbsolutePath: {glob: "**/content/intro-card.md"}) {
            frontmatter {
              title
              items {
                icon
                text
              }
            }
            html
          }
        }
    `);
    return { title, items, html };
};
